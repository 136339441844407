
<template>
	<div>
		{{imageUrl}}
		<OssUpload ref="ossUpload" imageType="agent" @change="changeImageUrl"></OssUpload>
	</div>

</template>
<script>
	import OssUpload from "@/components/OssUpload";
	import {BasicApi} from '@/api';
	export default {
		components: {
			OssUpload
		},
		data() {
			return {
				imageUrl: ''
			};
		},
		methods: {
			changeImageUrl(fileList, fileNames){
				this.imageUrl = fileNames && fileNames[0] || '';
			},
		}
	}
</script>
<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}
	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
